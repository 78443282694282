import dynamic from "next/dynamic";
import { FunctionComponent, ImgHTMLAttributes } from "react";
import { MajestIcon } from "./MajestIcon";
import { removeTrailing } from "utils/stringUtils";

export interface IconRendererProps {
    name: string
    size?: string
    style?: any
}

const majestIconPrefix = 'majesticon'
const muiIconPrefix = 'muiicon'
const typeUrl = 'url'

const MuiIcons = {
    KeyIcon: dynamic(() =>
        import('@mui/icons-material/Key').then(mod=>mod.default)
    ),
    SquareFootIcon: dynamic(() =>
        import('@mui/icons-material/SquareFoot').then(mod=>mod.default)
    ),
    CheckIcon: dynamic(() =>
        import('@mui/icons-material/Check').then(mod=>mod.default)
    ),
    CheckCircleIcon: dynamic(() =>
        import('@mui/icons-material/CheckCircle').then(mod=>mod.default)
    ),
    LayersIcon: dynamic(() =>
        import('@mui/icons-material/Layers').then(mod=>mod.default)
    ),
    SportsScoreIcon: dynamic(() =>
        import('@mui/icons-material/SportsScore').then(mod=>mod.default)
    ),
    GroupsIcon: dynamic(() =>
        import('@mui/icons-material/Groups').then(mod=>mod.default)
    ),
    FollowTheSignsIcon: dynamic(() =>
        import('@mui/icons-material/FollowTheSigns').then(mod=>mod.default)
    ),
}

export const IconRenderer: FunctionComponent<IconRendererProps  & ImgHTMLAttributes<HTMLImageElement>> = ({name, size, style}) => {
    size = size || '24px'

    let type = null
    let icon = null
    if (name?.includes(':')) {
        const parts = name.split(':')
        type   = parts[0]
        icon   = parts[1]
    }

    if (name.startsWith('http') ||name.startsWith('/')) {
        type = 'url'
        icon = name
    }


    const wrapperStyle = ()=>({
/*         width: '32px',
        height: '32px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', */
        '& img': {
            filter: 'invert(8%) sepia(100%) saturate(6481%) hue-rotate(198deg) brightness(162%) contrast(143%)',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    })

    if (!icon) {
        return null
    }

    if (type === majestIconPrefix) {
        return <div css={wrapperStyle}><MajestIcon name={icon} width={size} height={size}/></div>
    }

    if (type === muiIconPrefix) {
        const IconComponent = MuiIcons[icon]
        return <div css={wrapperStyle}>{<IconComponent sx={style} />}</div>
    }

    if (type === typeUrl) {
        return <img src={icon} width={removeTrailing(size,'px')} height={removeTrailing(size,'px')} alt="Icon" />
    }

    return null
}